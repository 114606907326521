import React from 'react'
import { Helmet } from 'react-helmet'

import Layout from 'components/Layout'
import Content from 'components/Content'
import SEO from 'components/Seo'
import GridTemplate from 'components/GridTemplate'

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Privacy Policy Okkhor.org" />
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <GridTemplate>
        <Content>
          <h2>Privacy Policy for <strong>okkhor.org</strong></h2>

          <p>All of your personal information collected will be used to help make your visit to this site as productive and enjoyable as possible.</p>

          <p>Ensuring the confidentiality of the personal data of users of this site is important to <strong>okkhor.org</strong>.</p>

          <p>Personal information collected may include your name, email, telephone and/or mobile number, address, date of birth and/or others.</p>

          <p>The use of <strong>okkhor.org</strong> presupposes the acceptance of this privacy agreement. The website <strong>okkhor.org</strong> reserves the right to change this agreement without prior notice. Therefore, we recommend that you check our privacy policy regularly so that you are always up to date.</p>

          <h2>The ads</h2>

          <p>Like other websites, we collect and use information contained in advertisements. The information contained in the advertisements includes your IP (Internet Protocol) address, your ISP (Internet Service Provider), the browser you used when visiting our website (such as Chrome, Edge, Safari, Internet Explorer or Firefox), your time visit and which pages you visited within our website.</p>

          <h2>Cookies and Web Beacons</h2>

          <p>We use cookies to store information such as your personal preferences when you visit our website. For example to save your theme preferences when you return to the site.</p>

          <p>The Application uses Google Analytics, an analytics service provided by Google, Inc. ("Google"). Google Analytics uses cookies to help us analyze how Users use the Application. The information collected in the cookie about how you use the Application (including your IP address) will be transmitted to and stored by Google on servers in the United States. Google will use this information for the purpose of evaluating how you use the Application, creating reports on activities on the Application and providing other services relating to activities on the Application and internet usage. Google may also transfer this information to third parties when required by law or for such third parties to process the information on behalf of and on behalf of Google. Google will not associate your IP address with any other data. Google's use of this data is governed by the Google Analytics Terms of Use and by <a href="https://policies.google.com/technologies/partner-sites"> Google Privacy Policy</a>.</p>

          <p>You have the power to turn off your cookies, in your browser options. However, this may change the way you interact with our website, or other websites. This may affect or not allow you to login to websites over the internet.</p>

          <h2>Connections to Third Party Websites</h2>

          <p>The <strong>okkhor.org</strong> has connections to other websites that may contain useful information. Our privacy policy does not apply to third-party websites, so if you visit another website from ours, you should read its privacy policy.</p>

          <p>We are not responsible for the privacy policy or content present on these sites.</p>
        </Content>
      </GridTemplate>
    </Layout>
  )
}

export default IndexPage
